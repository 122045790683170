import { ArrowRight } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BandShow, BandShowStatus } from "src/API";
import { RootState } from "src/stores";
import { getMyBandShowRequests, updateBandShow } from "../backend";

const useBandShowRequestsForUser = (id?: string | null) => {
  return useQuery(
    ["user", id, "bandShowRequests"],
    async () => {
      if (!id) {
        return null;
      }
      const bandShowRequests = await getMyBandShowRequests(id);
      return bandShowRequests;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const BandShowRequests = () => {
  const { id: userId } = useSelector((state: RootState) => state.user);
  const {
    data: bandShowRequests,
    isFetching,
    refetch,
  } = useBandShowRequestsForUser(userId);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const [isExpanded, setIsExpanded] = useState<boolean>(!isMobile);

  const handleAccept = async (bandShowRequestId: string) => {
    try {
      const result = await updateBandShow({
        id: bandShowRequestId,
        status: BandShowStatus.ACCEPTED,
      });
      if (result) {
        refetch();
      }
    } catch (e) {
      console.log("[ERROR] error accepting band show request", e);
    }
  };
  const handleReject = async (bandShowRequestId: string) => {
    try {
      const result = await updateBandShow({
        id: bandShowRequestId,
        status: BandShowStatus.REJECTED,
      });
      if (result) {
        refetch();
      }
    } catch (e) {
      console.log("[ERROR] error rejecting band show request", e);
    }
  };

  const renderBandShowRequests = () => {
    if (isFetching) {
      return <CircularProgress />;
    }
    if (!bandShowRequests || bandShowRequests.length === 0) {
      return null;
    }
    const allPendingBandShows = bandShowRequests.reduce((acc, curr) => {
      const { band } = curr;
      if (!band || !band.hasPendingBandShow) {
        return acc;
      }
      const { pendingBandShows } = band;
      if (!pendingBandShows || !pendingBandShows.items) {
        return acc;
      }
      const pendingShows = pendingBandShows.items.reduce((a, c) => {
        const { show } = c;
        if (!show) {
          return a;
        }
        return [...a, c];
      }, [] as BandShow[]) as BandShow[];
      return [...acc, ...pendingShows];
    }, [] as BandShow[]) as BandShow[];
    return (
      <Box sx={{ borderTop: "1px #f0f0f0 solid", mt: 3, pt: 1 }}>
        <Button
          onClick={() => setIsExpanded((e) => !e)}
          startIcon={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ArrowRight
                sx={{
                  fontSize: 40,
                  transition: "all 0.111s ease-in-out",
                  transform: isExpanded ? "rotate(90deg)" : undefined,
                }}
              />
            </Box>
          }>
          <Typography variant="h6">
            Band Show Requests{" "}
            {allPendingBandShows && `(${allPendingBandShows.length})`}
          </Typography>
        </Button>
        <Grid
          container
          spacing={1}
          sx={{ my: 1, display: isExpanded ? undefined : "none" }}>
          {allPendingBandShows.map((pendingBandShow, idx) => {
            const { band, show } = pendingBandShow;
            if (!show || !band) {
              return null;
            }
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={`bandrequest-${pendingBandShow.id}-${idx}`}>
                <Card>
                  {show?.showPictureUrl && (
                    <CardMedia
                      sx={{ position: "relative", cursor: "pointer" }}
                      onClick={() => navigate(`/shows/${show.id}`)}>
                      <img
                        height={200}
                        width="100%"
                        style={{
                          objectFit: "cover",
                          position: "relative",
                          zIndex: 999,
                        }}
                        src={show.showPictureUrl}
                        alt={show.name}
                      />
                      {band.profilePictureUrl && (
                        <img
                          src={band.profilePictureUrl}
                          width="100%"
                          style={{
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            width: "100%",
                            height: 200,
                            position: "absolute",
                            objectFit: "cover",
                            zIndex: 9999,
                            clipPath: "polygon(100% 0, 0% 100%, 100% 100%)",
                          }}
                          alt={band.name}
                        />
                      )}
                    </CardMedia>
                  )}
                  <CardContent>
                    <Typography>
                      {band.name} has been invited to the show {show?.name}
                    </Typography>
                  </CardContent>
                  <CardActions
                    sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      sx={{ display: "flex", flexGrow: 1 }}
                      onClick={() => navigate(`/shows/${show.id}`)}>
                      See Show
                    </Button>
                    <Divider sx={{ ml: 1 }} orientation="vertical" flexItem />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleAccept(pendingBandShow.id)}>
                      Accept
                    </Button>
                    <Button
                      onClick={() => handleReject(pendingBandShow.id)}
                      variant="contained">
                      Reject
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  return renderBandShowRequests();
};

export default BandShowRequests;
