import { Container } from "@mui/material";
import BandMemberRequests from "./segments/BandMemberRequests";
import BandShowRequests from "./segments/BandShowRequests";
import FriendshipRequests from "./segments/FriendshipRequests";

export const Notifications = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 5 }}>
      <BandMemberRequests />
      <BandShowRequests />
      <FriendshipRequests />
    </Container>
  );
};
