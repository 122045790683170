import {
  useState,
  SyntheticEvent,
  useReducer,
  ChangeEvent,
  useEffect,
} from "react";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Slide,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Band } from "src/API";
import {
  createBand,
  updateBand,
  saveBandBannerPicture,
  saveBandProfilePicture,
} from "./backend";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "src/stores";
import { useQueryClient } from "react-query";
import PhotoCropper from "src/components/PhotoCropper";
import { getImageUrl } from "src/utils/images";
import TabPanel from "src/components/TabPanel";
import { refreshSession } from "src/utils/auth";
import { LocationSearch } from "src/components/LocationSearch";
import { MusicNote, People, Refresh } from "@mui/icons-material";

type BandFormState = {
  name: string;
  description: string;
  slug?: string;
  city: string;
  state: string;
  zipcode: string;
  profilePictureUrl?: string | null;
  profileBannerUrl?: string | null;
};

type SetNameAction = {
  type: "SET_NAME";
  payload: string;
};
type SetDescriptionAction = {
  type: "SET_DESCRIPTION";
  payload: string;
};
type SetCityAction = {
  type: "SET_CITY";
  payload: string;
};
type SetStateAction = {
  type: "SET_STATE";
  payload: string;
};
type SetZipcodeAction = {
  type: "SET_ZIPCODE";
  payload: string;
};
type SetBandAction = {
  type: "SET_BAND";
  payload: BandFormState;
};
type SetBandImageAction = {
  type: "SET_BAND_IMAGE";
  payload: string;
};
type SetBandBannerAction = {
  type: "SET_BAND_BANNER";
  payload: string;
};
type SetBandSlugAction = {
  type: "SET_SLUG";
  payload: string;
};
type BandFormActions =
  | SetNameAction
  | SetDescriptionAction
  | SetCityAction
  | SetStateAction
  | SetZipcodeAction
  | SetBandAction
  | SetBandImageAction
  | SetBandBannerAction
  | SetBandSlugAction;

const newBandReducer = (state: BandFormState, action: BandFormActions) => {
  switch (action.type) {
    case "SET_BAND":
      return { ...action.payload };
    case "SET_NAME":
      return { ...state, name: action.payload };
    case "SET_SLUG":
      return { ...state, slug: action.payload };
    case "SET_DESCRIPTION":
      return { ...state, description: action.payload };
    case "SET_CITY":
      return { ...state, city: action.payload };
    case "SET_STATE":
      return { ...state, state: action.payload };
    case "SET_ZIPCODE":
      return { ...state, zipcode: action.payload };
    case "SET_BAND_IMAGE":
      return { ...state, profilePictureUrl: action.payload };
    case "SET_BAND_BANNER":
      return { ...state, profileBannerUrl: action.payload };
    default:
      return state;
  }
};

type BandFormProps = {
  isNew: boolean;
  band?: Band;
};

const BandForm = ({ isNew, band }: BandFormProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedTab = searchParams.get("tab");
  const [tabValue, setTabValue] = useState(
    selectedTab ? parseInt(selectedTab) : 0,
  );
  const [changingProfileImage, setChangingProfileImage] = useState(false);
  const [changingBannerImage, setChangingBannerImage] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSettingLocation, setIsSettingLocation] = useState<boolean>(false);

  const { id: userId } = useSelector((state: RootState) => state.user);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [state, dispatch] = useReducer(newBandReducer, {
    name: band?.name || "",
    description: band?.description || "",
    city: band?.city || "",
    state: band?.state || "",
    slug: band?.slug || "",
    zipcode: band?.zipcode || "",
    profilePictureUrl: band?.profilePictureUrl || "",
    profileBannerUrl: band?.profileBannerUrl || "",
  });

  useEffect(() => {
    if (band) {
      dispatch({
        type: "SET_BAND",
        payload: {
          name: band?.name || "",
          description: band?.description || "",
          city: band?.city || "",
          state: band?.state || "",
          slug: band?.slug || "abc-def-ghi",
          zipcode: band?.zipcode || "",
          profilePictureUrl: band?.profilePictureUrl || "",
          profileBannerUrl: band?.profileBannerUrl || "",
        },
      });
      if (!band.city || !band.state) {
        setIsSettingLocation(true);
      }
    }
  }, [band]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setSearchParams({ tab: newValue.toString() });
  };

  const handleStateChange = (
    event:
      | "SET_NAME"
      | "SET_DESCRIPTION"
      | "SET_CITY"
      | "SET_STATE"
      | "SET_ZIPCODE"
      | "SET_SLUG",
  ) => {
    return (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      dispatch({ type: event, payload: e.target.value });
  };

  const handleUploadProfile = async (url: string) => {
    const imageUrl = getImageUrl(url);
    dispatch({ type: "SET_BAND_IMAGE", payload: imageUrl });
    setChangingProfileImage(false);
    if (band && band.id) {
      await saveBandProfilePicture({
        bandId: band.id,
        profilePictureUrl: imageUrl,
      });
    }
  };

  const handleUploadBanner = async (url: string) => {
    const imageUrl = getImageUrl(url);
    dispatch({ type: "SET_BAND_BANNER", payload: imageUrl });
    setChangingBannerImage(false);
    if (band && band.id) {
      await saveBandBannerPicture({
        bandId: band.id,
        profileBannerUrl: imageUrl,
      });
    }
  };

  const handleLocationCancel = () => {
    setIsSettingLocation(false);
  };

  const handleLocationSelected = ({
    lat,
    lng,
    city,
    state,
  }: {
    lat: number;
    lng: number;
    city: string;
    state: string;
  }) => {
    dispatch({ type: "SET_CITY", payload: city });
    dispatch({ type: "SET_STATE", payload: state });
    setIsSettingLocation(false);
  };

  const handleSave = async () => {
    if (!userId) {
      return;
    }
    try {
      setIsSaving(true);
      const input = {
        name: state.name,
        description: state.description,
        city: state.city,
        state: state.state,
        slug: state.slug,
        zipcode: state.zipcode,
        creatorId: userId,
        profilePictureUrl: state.profilePictureUrl,
        profileBannerUrl: state.profileBannerUrl,
      };
      setError(null);
      if (isNew) {
        const newBand = await createBand(input);
        await refreshSession();
        if (newBand) {
          navigate(`/bands/${newBand.id}`);
        }
      } else if (band && band.id) {
        const updatedBand = await updateBand({
          ...input,
          id: band.id,
        });
        if (updatedBand) {
          // navigate(`/bands/${updatedBand.id}`);
          setIsSaved(true);
          queryClient.invalidateQueries(["bands", updatedBand.id]);
          setTimeout(() => {
            setIsSaved(false);
          }, 2000);
        }
      }
    } catch (error) {
      console.log("[ERROR] error creating/updating band", error);
      setError("Error creating/updating band. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  if (!isNew && !band) {
    alert("missing something");
    return (
      <Container maxWidth="md">
        <Typography>Invalid params</Typography>
      </Container>
    );
  }
  return (
    <Box>
      {error && <Alert severity="error">{error}</Alert>}
      <Snackbar
        open={isSaved}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Slide}>
        <Alert severity="success" variant="filled">
          Band saved!
        </Alert>
      </Snackbar>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "flex-end",
          my: { xs: 2, md: 0 },
        }}>
        <Button
          variant="contained"
          startIcon={<People />}
          onClick={() => navigate(`/bands/${band?.id}/members`)}>
          Manage Members
        </Button>
        <Button
          variant="contained"
          startIcon={<MusicNote />}
          onClick={() => navigate(`/bands/${band?.id}/songs`)}
          sx={{ ml: { xs: 0, md: 1 }, mt: { xs: 1, md: 0 } }}>
          Manage Songs
        </Button>
      </Box>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        sx={{
          mb: 3,
        }}
        aria-label="basic tabs example">
        <Tab label="General" />
        <Tab label="Location" />
        <Tab label="Images" />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <TextField
            label="name"
            value={state.name}
            onChange={handleStateChange("SET_NAME")}
            sx={{ mb: 2 }}
          />
          <TextField
            label="description"
            multiline
            onChange={handleStateChange("SET_DESCRIPTION")}
            value={state.description}
            rows={4}
            sx={{ mb: 2 }}
          />
          <TextField
            label="URL Slug"
            value={state.slug}
            helperText={`URL slug https://tothemusic.com/b/${
              state.slug || "<slug>"
            }`}
            onChange={handleStateChange("SET_SLUG")}
            sx={{ mb: 2 }}
          />
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {state.city && state.state && !isSettingLocation && (
            <Box
              sx={{
                mb: 5,
                display: "flex",
                alignItems: "center",
              }}>
              <Box>
                <Typography>Location:</Typography>
                <Typography fontWeight="bold" variant="h6">
                  {state.city}, {state.state}
                </Typography>
              </Box>
              <Button
                variant="outlined"
                sx={{ ml: 2 }}
                size="small"
                startIcon={<Refresh />}
                onClick={() => setIsSettingLocation(true)}>
                Change
              </Button>
            </Box>
          )}
          {isSettingLocation && (
            <LocationSearch
              onLocationCancelled={handleLocationCancel}
              onLatLongSelected={handleLocationSelected}
              sx={{ mb: 5 }}
            />
          )}
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Grid container spacing={1} sx={{ mb: 5 }}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ mb: 1, display: "flex", alignItems: "center" }}>
              <Typography variant="h6">Band Profile Image</Typography>
              {state.profilePictureUrl && (
                <Button
                  sx={{ ml: 1 }}
                  variant="outlined"
                  onClick={() => setChangingProfileImage(true)}>
                  Change
                </Button>
              )}
            </Box>
            {(changingProfileImage || !state.profilePictureUrl) && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}>
                <PhotoCropper
                  onCancel={() => null}
                  onComplete={handleUploadProfile}
                  showPreviewSquare={true}
                  showPreviewCircle={true}
                  aspect={1}
                  containerSx={{ width: { xs: "100%", md: "550px" } }}
                  imageSx={{ width: { xs: "100%", md: "400px" } }}
                  keyFolder="band-profile"
                />
                <Button onClick={() => setChangingProfileImage(false)}>
                  Cancel
                </Button>
              </Box>
            )}
            {!changingProfileImage && state.profilePictureUrl && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}>
                <img
                  src={state.profilePictureUrl}
                  alt="profile"
                  style={{ height: 150, width: 150 }}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ mb: 1, display: "flex", alignItems: "center" }}>
              <Typography variant="h6">Band Profile Banner</Typography>
              <Button
                sx={{ ml: 1 }}
                variant="outlined"
                onClick={() => setChangingBannerImage(true)}>
                Change
              </Button>
            </Box>
            {(changingBannerImage || !state.profileBannerUrl) && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}>
                <PhotoCropper
                  onCancel={() => null}
                  onComplete={handleUploadBanner}
                  showPreviewSquare={true}
                  aspect={2.67}
                  containerSx={{ width: { xs: "100%", md: "550px" } }}
                  imageSx={{ width: { xs: "100%", md: "400px" } }}
                  keyFolder="band-banner"
                />
                <Button onClick={() => setChangingBannerImage(false)}>
                  Cancel
                </Button>
              </Box>
            )}
            {!changingBannerImage && state.profileBannerUrl && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}>
                <img
                  src={state.profileBannerUrl}
                  alt="banner"
                  style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: 600,
                    maxHeight: 600 / 2.67,
                  }}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </TabPanel>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <LoadingButton
          loading={isSaving}
          variant="contained"
          sx={{ mr: 2 }}
          onClick={handleSave}>
          Save
        </LoadingButton>
        <Button onClick={() => navigate("/bands/mine")}>Cancel</Button>
      </Box>
    </Box>
  );
};

export default BandForm;
