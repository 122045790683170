import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import MapBox, { Layer, Marker, Source } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useEffect, useRef, useState } from "react";
import { LocationOn, RemoveRedEye } from "@mui/icons-material";
import { useQuery } from "react-query";
import {
  createBandMapPoint,
  deleteBandMapPoint,
  getBandMapPointsForBand,
  updateBandMapPoint,
} from "./backend";
import { useParams } from "react-router-dom";
import { round } from "src/utils/numbers";
import { isBefore } from "date-fns";

type Coords = {
  lat: number;
  lng: number;
};

type MarkerCoords = {
  id?: string;
  name?: string;
} & Coords;

const MARKER_LENGTH = 10;

const useBandMapPoints = (bandId?: string) => {
  return useQuery(["band", bandId, "map-points"], async () => {
    if (!bandId) {
      return null;
    }
    const points = await getBandMapPointsForBand(bandId);
    const sorted = points.sort((a, b) => {
      if (isBefore(new Date(a.createdAt), new Date(b.createdAt))) {
        return -1;
      }
      return 1;
    });
    return sorted;
  });
};

export const BandMap = () => {
  const { id: bandId } = useParams<{ id: string }>();
  const [markers, setMarkers] = useState<MarkerCoords[]>([]);
  const [zoom, setZoom] = useState(10);
  const ids = useRef(new Array(MARKER_LENGTH).fill(0).map((_, i) => i + 1));
  const [viewState, setViewState] = useState<Coords | null>(null);
  const [isLoadingLocation, setIsLoadingLocation] = useState(true);
  const [isEditing, setIsEditing] = useState<string | null>(null);
  const [editingMarker, setEditingMarker] = useState<MarkerCoords | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { data: bandMapPoints, isLoading: isLoadingBandMapPoints } =
    useBandMapPoints(bandId);

  useEffect(() => {
    if (navigator.geolocation) {
      setIsLoadingLocation(true);
      navigator.geolocation.getCurrentPosition((position) => {
        setIsLoadingLocation(false);
        setViewState({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
    const listener = (e: KeyboardEvent) => {
      if (e.key === "Enter" || e.key === "Escape") {
        setIsEditing(null);
        setEditingMarker(null);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  useEffect(() => {
    if (!bandMapPoints) {
      return;
    }
    const markers = bandMapPoints
      .map((point) => {
        if (!point.id || !point.latitude || !point.longitude) {
          return null;
        }
        console.log("point", point);
        return {
          id: point.id,
          name: point.name || undefined,
          lat: point.latitude,
          lng: point.longitude,
        };
      })
      .filter(Boolean);
    setMarkers(markers as MarkerCoords[]);
  }, [bandMapPoints]);

  const handleSaveMarker = async () => {
    if (!editingMarker) {
      console.log("no editing marker");
      return;
    }
    if (editingMarker.id) {
      const updatedMarker = await updateBandMapPoint({
        id: editingMarker.id,
        name: editingMarker.name,
        lat: editingMarker.lat,
        lng: editingMarker.lng,
      });
      if (!updatedMarker?.id) {
        setError("Error updating marker");
        return;
      }
    } else {
      if (!bandId) {
        console.log("no band id");
        return;
      }
      try {
        console.log("creating marker", editingMarker);
        const newMarker = await createBandMapPoint({
          bandId,
          name: editingMarker.name,
          lat: editingMarker.lat,
          lng: editingMarker.lng,
        });
        if (!newMarker?.id) {
          throw new Error("No id returned");
        }
      } catch (e) {
        console.log("error", e);
        setMarkers(markers.filter((m) => Boolean(m.id)));
      }
    }
    setIsEditing(null);
    setEditingMarker(null);
  };
  const handleAddMarker = ({ lat, lng }: { lat: number; lng: number }) => {
    const newMarker = { lat, lng };
    setEditingMarker(newMarker);
    setMarkers([...markers, newMarker]);
  };

  const handleRemoveMarker = async (marker?: MarkerCoords | null) => {
    if (!marker || !marker.id) {
      return;
    }
    const result = await deleteBandMapPoint(marker.id);
    if (!result) {
      return;
    }
    setMarkers(markers.filter((m) => marker.id !== m.id));
    setEditingMarker(null);
    setIsEditing(null);
  };
  if (isLoadingBandMapPoints) {
    return (
      <Container maxWidth="lg" sx={{ mt: 3 }}>
        <CircularProgress />
      </Container>
    );
  }
  return (
    <Container maxWidth="lg" sx={{ my: 3 }}>
      {error && <Alert severity="error">{error}</Alert>}
      {isLoadingLocation && <CircularProgress />}
      {viewState && (
        <>
          <MapBox
            initialViewState={{
              longitude: viewState.lng,
              latitude: viewState.lat,
              zoom,
            }}
            doubleClickZoom={false}
            onMove={({ viewState }) => {
              setZoom(viewState.zoom);
              setViewState({
                lat: viewState.latitude,
                lng: viewState.longitude,
              });
            }}
            latitude={viewState.lat}
            longitude={viewState.lng}
            maxZoom={10}
            minZoom={6}
            onZoom={(e) => setZoom(e.viewState.zoom)}
            onDblClick={(e) => {
              handleAddMarker(e.lngLat);
            }}
            style={{ width: "100%", height: 600 }}
            mapStyle="mapbox://styles/mapbox/streets-v9">
            {markers.map((marker, i) => (
              <Marker
                key={`marker-${i}`}
                longitude={marker.lng}
                onClick={() => setEditingMarker(marker)}
                latitude={marker.lat}>
                <Box sx={{ position: "relative" }}>
                  <LocationOn />
                  <Box
                    sx={{
                      position: "absolute",
                      left: -40,
                      bottom: -35,
                      right: -40,
                      display: zoom > 7 ? "flex" : "none",
                      justifyContent: "center",
                    }}>
                    <Typography
                      sx={{
                        px: 1,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        backgroundColor: "white",
                        borderRadius: 10,
                      }}
                      variant="caption">
                      {`#${i + 1} ${marker.name || ""}`}
                    </Typography>
                  </Box>
                </Box>
              </Marker>
            ))}
            <Source
              id="my-data"
              type="geojson"
              data={{
                type: "FeatureCollection",
                features: markers.map((marker) => {
                  return {
                    type: "Feature",
                    properties: {},
                    geometry: {
                      type: "Point",
                      coordinates: [marker.lng, marker.lat],
                    },
                  };
                }),
              }}>
              <Layer
                id="point"
                type="circle"
                paint={{
                  "circle-radius": 20 + 1.2 ** zoom,
                  "circle-color": "rgba(150,0,0,0.5)",
                }}
              />
            </Source>
          </MapBox>
          <Alert severity="info">
            Double-click to add a new location. Single click marker to edit.
            <Typography variant="caption" component="div">
              Press "Enter" or "Escape" to close.
            </Typography>
          </Alert>
        </>
      )}
      <Box
        sx={{
          my: 2,
          display: "flex",
          alignItems: "center",
          alignContent: "center",
        }}>
        <Typography variant="h6">
          My Points ({markers.length} / {MARKER_LENGTH})
        </Typography>

        {markers && markers.length > 0 && (
          <Button
            color="error"
            onClick={() => {
              ids.current = new Array(MARKER_LENGTH)
                .fill(0)
                .map((_, i) => i + 1);
              setMarkers([]);
            }}>
            Remove all
          </Button>
        )}
      </Box>
      <Grid container spacing={3}>
        {markers
          .sort((a, b) => {
            if (!a.id || !b.id) {
              return 0;
            }
            return a.id < b.id ? -1 : 1;
          })
          .map((marker, i) => (
            <Grid item xs={12} sm={4} key={`marker-desc-${i}`}>
              <Card>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <Typography variant="h5">{`#${i + 1}`}</Typography>
                    {isEditing !== marker.id && (
                      <Typography
                        onClick={() => {
                          if (marker.id) {
                            setEditingMarker(marker);
                          }
                        }}>
                        {marker.name || "**Add Name**"}
                      </Typography>
                    )}
                    {isEditing === marker.id && (
                      <TextField
                        autoFocus
                        onBlur={() => setIsEditing(null)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSaveMarker();
                          }
                        }}
                        size="small"
                        onChange={(e) => {
                          const newMarkers = [...markers];
                          newMarkers[i].name = e.target.value;
                          setMarkers(newMarkers);
                        }}
                        value={marker.name}
                        title="Marker Name"
                      />
                    )}
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="caption">
                      ({round(marker.lat, 6)}, {round(marker.lng, 6)})
                    </Typography>
                  </Box>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    startIcon={<RemoveRedEye />}
                    onClick={() => {
                      setViewState({ lat: marker.lat, lng: marker.lng });
                    }}>
                    View
                  </Button>
                  <Button
                    color="error"
                    size="small"
                    onClick={() => handleRemoveMarker(marker)}>
                    Remove
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
      <Dialog
        open={editingMarker !== null}
        fullWidth
        onClose={handleSaveMarker}>
        <DialogTitle
          display="flex"
          flexDirection="row"
          justifyContent="space-between">
          Editing Marker {editingMarker?.name || ""}
          <Button onClick={handleSaveMarker}>Close</Button>
        </DialogTitle>
        <DialogContent>
          {editingMarker && (
            <TextField
              autoFocus
              fullWidth
              placeholder="Marker name"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSaveMarker();
                }
              }}
              onChange={(e) => {
                const idx = markers.findIndex(
                  (m) => m.id === editingMarker?.id,
                );
                if (isNaN(idx)) {
                  return;
                }

                const newMarkers = [...markers];
                newMarkers[idx].name = e.target.value;
                setMarkers(newMarkers);
              }}
              value={editingMarker?.name}
              title="Marker name"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSaveMarker}>
            Done
          </Button>
          <Button
            onClick={() => handleRemoveMarker(editingMarker)}
            variant="outlined"
            color="error">
            Delete Marker
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
