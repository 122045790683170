import { GraphQLResult } from "@aws-amplify/api-graphql";
import { API, Auth, graphqlOperation } from "aws-amplify";

type GraphQLOptions = {
  query: string;
  variables?: { [key: string]: any };
  authMode: "API_KEY" | "AWS_IAM" | "AMAZON_COGNITO_USER_POOLS";
};

/**
 * Note: This method should only be used when we do not care
 * about the user's authentication status.
 */
export const publicGraphqlOperation = (
  query: string,
  variables?: object,
): GraphQLOptions => ({
  query,
  variables,
  authMode: "API_KEY",
});

/**
 * This is the preferred method to use when we want to
 * make a GraphQL request to the backend.
 */
export const apiGraphqlOperationWithAuth = async <T>(
  query: string,
  variables?: object,
): Promise<GraphQLResult<T> | null> => {
  let isAuthed = false;
  try {
    isAuthed = Boolean(await Auth.currentAuthenticatedUser());
  } catch (e) {
    isAuthed = false;
  }
  let result;
  if (isAuthed) {
    result = (await API.graphql(
      graphqlOperation(query, variables),
    )) as GraphQLResult<T>;
  } else {
    result = (await API.graphql(
      publicGraphqlOperation(query, variables),
    )) as GraphQLResult<T>;
  }

  return result;
};
