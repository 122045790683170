/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:30e15b37-c513-4c47-a66c-edf86a4f4f08",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_UJYFAXju7",
    "aws_user_pools_web_client_id": "5ufh78pouakaf9u62epseru0bg",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://hwtkshnzfjeipi2xscyc6w6lp4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-6ulmec4jcjf5tf6gznrtjvdi5m",
    "aws_user_files_s3_bucket": "tothemusic-app-storage195702-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
