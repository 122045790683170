import { Typography, Container, Box, Grid, Link } from "@mui/material";

import { Link as RRLink } from "react-router-dom";

export const Footer = () => {
  return (
    <Box sx={{ borderTop: "1px #f5f5f5 solid" }}>
      <Container
        maxWidth="lg"
        sx={{
          mt: 3,
        }}>
        <Grid container spacing={2} sx={{ minHeight: "20vh", pb: 5 }}>
          <Grid item xs={12} sm={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Link component={RRLink} to="/">
                Home
              </Link>
              <Link component={RRLink} to="/">
                About Us
              </Link>
              <Link component={RRLink} to="/">
                Terms &amp; Conditions
              </Link>
              <Link component={RRLink} to="/">
                Privacy Policy
              </Link>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "center" },
              alignItems: { xs: "center", md: "center" },
            }}>
            <Typography textAlign="center" mt={2} variant="body1">
              Made with ❤️ by{" "}
              <a href="https://davidbrear.com" target="_blank" rel="noreferrer">
                david
              </a>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "center" },
              alignItems: { xs: "center", md: "center" },
            }}>
            <Typography variant="body2">
              &copy; {new Date().getFullYear()} ToTheMusic LLC. All rights
              reserved.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
