import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import {
  BandMember,
  Band,
  User,
  Song,
  SongStatus,
  BandFan,
  BandPost,
  BandShow,
  Show,
  ShowGoing,
  BandShowStatus,
  BandMemberRequest,
  BandHighlight,
  BandMapPoint,
} from "src/API";
import * as queries from "./queries";
import * as mutations from "./mutations";
import { getTimeStamp } from "src/utils/time";
import { Auth } from "aws-amplify";
import { apiGraphqlOperationWithAuth } from "src/utils/query";

type CreateBandProps = {
  name: string;
  description: string;
  city: string;
  state: string;
  slug?: string | null;
  zipcode: string;
  creatorId: string;
  profilePictureUrl?: string | null;
  profileBannerUrl?: string | null;
};

export const createBand = async ({
  name,
  description,
  city,
  state,
  zipcode,
  slug,
  creatorId,
  profilePictureUrl,
  profileBannerUrl,
}: CreateBandProps): Promise<Band | undefined> => {
  const input = {
    name,
    description,
    city,
    state,
    slug,
    zipcode,
    creatorId,
    profilePictureUrl,
    profileBannerUrl,
  };
  const result = (await API.graphql(
    graphqlOperation(mutations.createBand, { input }),
  )) as GraphQLResult<{ createBand: Band }>;

  if (result.errors) {
    throw result.errors;
  }

  return result.data?.createBand;
};

type UpdateBandProps = {
  id: string;
  name: string;
  description: string;
  slug?: string | null;
  city: string;
  state: string;
  zipcode: string;
  profilePictureUrl?: string | null;
  profileBannerUrl?: string | null;
};

export const updateBand = async ({
  id,
  name,
  description,
  slug,
  city,
  state,
  zipcode,
  profilePictureUrl,
  profileBannerUrl,
}: UpdateBandProps): Promise<Band | undefined> => {
  const input = {
    id,
    name,
    description,
    slug,
    city,
    state,
    zipcode,
    profilePictureUrl,
    profileBannerUrl,
  };
  const result = (await API.graphql(
    graphqlOperation(mutations.updateBand, { input }),
  )) as GraphQLResult<{ updateBand: Band }>;

  if (result.errors) {
    throw result.errors;
  }

  return result.data?.updateBand;
};

export const getMyBandMembershipsForUser = async (
  id: string,
): Promise<BandMember[]> => {
  const result = (await API.graphql(
    graphqlOperation(queries.bandMembersByUser, { userId: id }),
  )) as GraphQLResult<{
    bandMembersByUser: { items: BandMember[]; nextToken: string | null };
  }>;

  if (result.errors) {
    throw result.errors;
  }
  if (result.data?.bandMembersByUser) {
    return result.data.bandMembersByUser.items;
  }
  return [];
};

export const getBand = async (id: string): Promise<Band | undefined> => {
  // const result = (await API.graphql(
  //   graphqlOperation(queries.getBand, { id }),
  // )) as GraphQLResult<{ getBand: Band }>;

  const result = (await apiGraphqlOperationWithAuth(queries.getBand, {
    id,
  })) as GraphQLResult<{ getBand: Band }>;

  return result.data?.getBand;
};

export const getBandsPublic = async (): Promise<Band[] | undefined> => {
  try {
    const result = (await apiGraphqlOperationWithAuth(
      queries.listBandHighlights,
      {
        limit: 25,
      },
    )) as GraphQLResult<{ listBandHighlights: { items: BandHighlight[] } }>;
    if (result.errors) {
      console.log("[ERROR]", result.errors);
      throw result.errors;
    }

    if (result.data?.listBandHighlights) {
      const highlights = result.data.listBandHighlights.items.filter(
        (bh) => Boolean(bh) && Boolean(bh.band),
      ) as BandHighlight[];
      const bands = highlights.map((bh) => bh.band) as Band[];
      return bands;
    }
  } catch (e) {
    console.log("[ERROR] error on public api: ", e);
  }
  return undefined;
};

export const getBandMembers = async (id: string): Promise<BandMember[]> => {
  const result = (await API.graphql(
    graphqlOperation(queries.bandMembersByBand, { bandId: id }),
  )) as GraphQLResult<{
    bandMembersByBand: { items: BandMember[]; nextToken: string | null };
  }>;

  if (result.errors) {
    throw result.errors;
  }
  return result.data?.bandMembersByBand.items ?? [];
};

export const searchUsers = async (query: string): Promise<User[]> => {
  const result = (await API.graphql(
    graphqlOperation(queries.searchUsers, { query }),
  )) as GraphQLResult<{
    searchUsers: { items: User[]; nextToken: string | null };
  }>;

  return result.data?.searchUsers.items ?? [];
};

export const searchBands = async (query: string): Promise<Band[]> => {
  const result = (await apiGraphqlOperationWithAuth(queries.searchBands, {
    query,
  })) as GraphQLResult<{
    searchBands: { items: Band[]; nextToken: string | null };
  }>;
  return result.data?.searchBands.items ?? [];
};

export const saveBandProfilePicture = async ({
  bandId,
  profilePictureUrl,
}: {
  bandId: string;
  profilePictureUrl: string;
}): Promise<Band> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateBand, {
      input: {
        id: bandId,
        profilePictureUrl,
      },
    }),
  )) as GraphQLResult<{ updateBand: Band }>;

  if (result.errors) {
    throw result.errors;
  }
  if (result.data?.updateBand) {
    return result.data?.updateBand;
  }
  throw new Error("Could not update band");
};

export const saveBandBannerPicture = async ({
  bandId,
  profileBannerUrl,
}: {
  bandId: string;
  profileBannerUrl: string;
}): Promise<Band> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateBand, {
      input: {
        id: bandId,
        profileBannerUrl,
      },
    }),
  )) as GraphQLResult<{ updateBand: Band }>;

  if (result.errors) {
    throw result.errors;
  }
  if (result.data?.updateBand) {
    return result.data?.updateBand;
  }
  throw new Error("Could not update band");
};

export const getSongsForBand = async (bandId: string): Promise<Song[]> => {
  const result = (await API.graphql(
    graphqlOperation(queries.songsByBand, {
      bandId,
    }),
  )) as GraphQLResult<{
    songsByBand: { items: Song[]; nextToken: string | null };
  }>;

  if (result.errors) {
    throw result.errors;
  }
  if (result.data?.songsByBand) {
    return result.data.songsByBand.items;
  }
  throw new Error("Could not get songs");
};

export const getActiveSongsForBand = async (
  bandId: string,
): Promise<Song[]> => {
  const result = (await apiGraphqlOperationWithAuth(
    queries.songsByBandAndStatus,
    {
      bandId,
      status: { eq: SongStatus.ACTIVE },
    },
  )) as GraphQLResult<{
    songsByBandAndStatus: { items: Song[]; nextToken: string | null };
  }>;

  if (result.errors) {
    throw result.errors;
  }
  if (result.data?.songsByBandAndStatus) {
    return result.data.songsByBandAndStatus.items;
  }
  throw new Error("Could not get songs");
};

type CreateSongProps = {
  creatorId: string;
  bandId: string;
  name: string;
  albumName: string;
  durationSeconds: number;
  songUrl: string;
  songPictureUrl?: string | null;
};

export const createSong = async ({
  creatorId,
  name,
  bandId,
  albumName,
  durationSeconds,
  songUrl,
  songPictureUrl,
}: CreateSongProps): Promise<Song> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.createSong, {
      input: {
        creatorId,
        name,
        bandId,
        albumName,
        durationSeconds,
        songUrl,
        songPictureUrl,
        status: "ACTIVE",
      },
    }),
  )) as GraphQLResult<{ createSong: Song }>;

  if (result.errors) {
    throw result.errors;
  }
  if (result.data?.createSong) {
    return result.data.createSong;
  }
  throw new Error("Could not create song");
};

export const updateSong = async ({
  id,
  name,
  albumName,
  songPictureUrl,
}: {
  id: string;
  name: string;
  albumName: string;
  songPictureUrl?: string | null;
}): Promise<Song> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateSong, {
      input: {
        id,
        name,
        albumName,
        songPictureUrl,
      },
    }),
  )) as GraphQLResult<{ updateSong: Song }>;

  if (result.errors) {
    throw result.errors;
  }
  if (result.data?.updateSong) {
    return result.data.updateSong;
  }
  throw new Error("Could not update song");
};

export const updateSongStatus = async ({
  songId,
  status,
}: {
  songId: string;
  status: SongStatus;
}): Promise<Song> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateSong, {
      input: {
        id: songId,
        status,
      },
    }),
  )) as GraphQLResult<{ updateSong: Song }>;

  if (result.errors) {
    throw result.errors;
  }
  if (result.data?.updateSong) {
    return result.data.updateSong;
  }
  throw new Error("Could not update song");
};

type InviteMemberResponse = {
  success: boolean;
  message: string;
};

export const inviteUserToBand = async ({
  userId,
  bandId,
}: {
  userId: string;
  bandId: string;
}): Promise<InviteMemberResponse> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.inviteBandMember, { userId, bandId }),
  )) as GraphQLResult<{ inviteBandMember: InviteMemberResponse }>;

  if (result.errors) {
    throw result.errors;
  }
  if (result.data?.inviteBandMember) {
    return result.data?.inviteBandMember;
  }
  return { success: false, message: "Unknown error" };
};

export const createBandFan = async ({
  userId,
  bandId,
}: {
  userId: string;
  bandId: string;
}): Promise<BandFan> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.createBandFan, {
      input: {
        userId,
        bandId,
        creatorId: userId,
      },
    }),
  )) as GraphQLResult<{ createBandFan: BandFan }>;

  if (result.errors) {
    console.log(
      "[ERROR] error creating band fan",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data?.createBandFan) {
    return result.data.createBandFan;
  }
  throw new Error("Could not create band fan");
};

export const deleteBandFan = async (bandFanId: string): Promise<BandFan> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.deleteBandFan, {
      input: {
        id: bandFanId,
      },
    }),
  )) as GraphQLResult<{ deleteBandFan: BandFan }>;

  if (result.errors) {
    console.log(
      "[ERROR] error deleting band fan",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data?.deleteBandFan) {
    return result.data.deleteBandFan;
  }
  throw new Error("Could not delete band fan");
};

type BandFanResponse = {
  isFan: boolean;
  bandFan: BandFan | null;
};

export const getBandFanByUserIdAndBandId = async ({
  userId,
  bandId,
}: {
  userId: string;
  bandId: string;
}): Promise<BandFanResponse> => {
  const result = (await API.graphql(
    graphqlOperation(queries.bandFansByUserAndBand, {
      userId,
      bandId: { eq: bandId },
    }),
  )) as GraphQLResult<{ bandFansByUserAndBand: { items: BandFan[] } }>;

  if (result.errors) {
    throw result.errors;
  }
  if (
    result.data?.bandFansByUserAndBand &&
    result.data.bandFansByUserAndBand.items.length > 0
  ) {
    return {
      isFan: true,
      bandFan: result.data.bandFansByUserAndBand.items[0],
    };
  }
  return { isFan: false, bandFan: null };
};

export const getBandPostsByBand = async (id: string): Promise<BandPost[]> => {
  const result = (await apiGraphqlOperationWithAuth(
    queries.bandPostsByBandAndDate,
    {
      bandId: id,
      sortDirection: "DESC",
    },
  )) as GraphQLResult<{ bandPostsByBandAndDate: { items: BandPost[] } }>;

  if (result.errors) {
    throw result.errors;
  }
  if (result.data?.bandPostsByBandAndDate) {
    return result.data.bandPostsByBandAndDate.items;
  }
  throw new Error("Could not get band posts");
};

export const updateBandMember = async ({
  memberId,
  instrument,
}: {
  memberId: string;
  instrument: string;
}) => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateBandMember, {
      input: {
        id: memberId,
        instrument,
      },
    }),
  )) as GraphQLResult<{ updateBandMember: BandMember }>;
  if (result.errors) {
    console.log(
      "[ERROR] error updating band member",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }

  if (result.data?.updateBandMember) {
    return result.data.updateBandMember;
  }
  throw new Error("Could not update band member");
};

export const createBandPost = async ({
  creatorId,
  bandId,
  content,
  postImageUrls,
}: {
  creatorId: string;
  bandId: string;
  content: string;
  postImageUrls?: string[] | null;
}) => {
  const result = (await API.graphql(
    graphqlOperation(mutations.createBandPost, {
      input: {
        bandId,
        content,
        creatorId,
        postImageUrls,
        bandGroup: `${bandId}-members`,
      },
    }),
  )) as GraphQLResult<{ createBandPost: BandPost }>;

  if (result.errors) {
    console.log(
      "[ERROR] error creating band post",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data?.createBandPost) {
    return result.data.createBandPost;
  }
  throw new Error("Could not create band post");
};

export const deleteBandPost = async (id: string): Promise<BandPost> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.deleteBandPost, {
      input: {
        id,
      },
    }),
  )) as GraphQLResult<{ deleteBandPost: BandPost }>;

  if (result.errors) {
    console.log(
      "[ERROR] error deleting band post",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data?.deleteBandPost) {
    return result.data.deleteBandPost;
  }
  throw new Error("Could not delete band post");
};

export const getBandHighlights = async (
  id: string,
): Promise<BandHighlight[]> => {
  const result = (await apiGraphqlOperationWithAuth(
    queries.bandHighlightsByBand,
    { bandId: id },
  )) as GraphQLResult<{ bandHighlightsByBand: { items: BandHighlight[] } }>;

  if (result.errors) {
    throw result.errors;
  }

  if (result.data?.bandHighlightsByBand) {
    return result.data.bandHighlightsByBand.items;
  }
  throw new Error("Could not get band highlights");
};

export const upcomingShowsForBand = async (id: string): Promise<Show[]> => {
  const result = (await apiGraphqlOperationWithAuth(
    queries.bandShowsByBandAndDate,
    {
      bandId: id,
      date: { ge: getTimeStamp() },
      filter: {
        status: { eq: BandShowStatus.ACCEPTED },
      },
    },
  )) as GraphQLResult<{ bandShowsByBandAndDate: { items: BandShow[] } }>;
  if (result.errors) {
    console.log(
      "[ERROR] error getting upcoming shows for band",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }

  if (result.data?.bandShowsByBandAndDate) {
    return result.data.bandShowsByBandAndDate.items
      .map((show) => show.show)
      .filter((show) => Boolean(show)) as Show[];
  }
  throw new Error("Could not get upcoming shows for band");
};

export const addShowGoing = async ({
  userId,
  showId,
}: {
  userId: string;
  showId: string;
}): Promise<ShowGoing> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.createShowGoing, { input: { userId, showId } }),
  )) as GraphQLResult<{ createShowGoing: ShowGoing }>;

  if (result.errors) {
    console.log(
      "[ERROR] error adding show going",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data?.createShowGoing) {
    return result.data.createShowGoing;
  }
  throw new Error("Could not add show going");
};

export const removeShowGoing = async ({
  userId,
  showId,
}: {
  userId: string;
  showId: string;
}): Promise<boolean> => {
  const goings = (await API.graphql(
    graphqlOperation(queries.showGoingsByUserAndShow, {
      userId,
      showId: { eq: showId },
    }),
  )) as GraphQLResult<{ showGoingsByUserAndShow: { items: ShowGoing[] } }>;
  if (goings.errors) {
    console.log(
      "[ERROR] error getting show goings",
      JSON.stringify(goings.errors, null, 2),
    );
    throw goings.errors;
  }

  const showGoings = goings.data?.showGoingsByUserAndShow?.items;
  if (showGoings && showGoings.length > 0) {
    const result = await Promise.all(
      showGoings.map(async (going: ShowGoing) => {
        const deleted = (await API.graphql(
          graphqlOperation(mutations.deleteShowGoing, {
            input: { id: going.id },
          }),
        )) as GraphQLResult<{ deleteShowGoing: ShowGoing }>;

        if (deleted.data?.deleteShowGoing) {
          return true;
        }
        return false;
      }),
    );
    if (result.some((r) => !r)) {
      throw new Error("Could not remove show going");
    }
    return true;
  }
  throw new Error("Could not remove show going");
};

export const getBandMemberRequestsForBand = async (
  bandId: string,
): Promise<BandMemberRequest[]> => {
  const result = (await API.graphql(
    graphqlOperation(queries.bandMemberRequestsByBand, {
      bandId,
    }),
  )) as GraphQLResult<{
    bandMemberRequestsByBand: { items: BandMemberRequest[] };
  }>;

  if (result.errors) {
    console.log(
      "[ERROR] error getting band member requests",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }

  if (result.data?.bandMemberRequestsByBand) {
    return result.data.bandMemberRequestsByBand.items;
  }
  throw new Error("Could not get band member requests");
};

export const createBandHighlight = async (
  bandId: string,
): Promise<BandHighlight | null> => {
  const me = await Auth.currentAuthenticatedUser();
  if (!me) {
    return null;
  }
  const result = (await API.graphql(
    graphqlOperation(mutations.createBandHighlight, {
      input: {
        createdById: me.username,
        bandId,
      },
    }),
  )) as GraphQLResult<{ createBandHighlight: BandHighlight }>;

  if (result.errors) {
    console.log(
      "[ERROR] error creating band highlight",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data?.createBandHighlight) {
    return result.data.createBandHighlight;
  }
  throw new Error("Could not create band highlight");
};

export const removeBandHighlight = async (
  highlightId: string,
): Promise<boolean> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.deleteBandHighlight, {
      input: {
        id: highlightId,
      },
    }),
  )) as GraphQLResult<{ deleteBandHighlight: BandHighlight }>;

  if (result.errors) {
    console.log(
      "[ERROR] error deleting band highlight",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data?.deleteBandHighlight) {
    return true;
  }
  throw new Error("Could not delete band highlight");
};

export const getBandMapPointsForBand = async (
  bandId: string,
): Promise<BandMapPoint[]> => {
  const result = (await API.graphql(
    graphqlOperation(queries.bandMapPointsByBand, {
      bandId,
    }),
  )) as GraphQLResult<{
    bandMapPointsByBand: { items: BandMapPoint[] };
  }>;

  if (result.errors) {
    console.log(
      "[ERROR] error getting band map points",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }

  if (result.data?.bandMapPointsByBand) {
    return result.data.bandMapPointsByBand.items;
  }
  throw new Error("Could not get band map points");
};

export const createBandMapPoint = async ({
  bandId,
  name,
  lat,
  lng,
  radius = 20,
}: {
  bandId: string;
  name?: string | null;
  lat: number;
  lng: number;
  radius?: number;
}): Promise<BandMapPoint | null> => {
  const creator = await Auth.currentAuthenticatedUser();
  if (!creator) {
    return null;
  }
  const result = (await API.graphql(
    graphqlOperation(mutations.createBandMapPoint, {
      input: {
        creatorId: creator.username,
        bandId,
        name,
        latitude: lat,
        longitude: lng,
        radius,
        _geoloc: {
          lat,
          lng,
        },
      },
    }),
  )) as GraphQLResult<{ createBandMapPoint: BandMapPoint }>;

  if (result.errors) {
    console.log(
      "[ERROR] error creating band map point",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data?.createBandMapPoint) {
    return result.data.createBandMapPoint;
  }
  throw new Error("Could not create band map point");
};

export const deleteBandMapPoint = async (id: string): Promise<boolean> => {
  console.log("deleting id", id);
  const result = (await API.graphql(
    graphqlOperation(mutations.deleteBandMapPoint, {
      input: {
        id,
      },
    }),
  )) as GraphQLResult<{ deleteBandMapPoint: BandMapPoint }>;

  if (result.errors) {
    console.log(
      "[ERROR] error deleting band map point",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data?.deleteBandMapPoint) {
    return true;
  }
  throw new Error("Could not delete band map point");
};

export const updateBandMapPoint = async ({
  id,
  name,
  lat,
  lng,
}: {
  id: string;
  name?: string | null;
  lat: number;
  lng: number;
}): Promise<BandMapPoint | null> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateBandMapPoint, {
      input: {
        id,
        name,
        latitude: lat,
        longitude: lng,
        _geoloc: {
          lat,
          lng,
        },
      },
    }),
  )) as GraphQLResult<{ updateBandMapPoint: BandMapPoint }>;

  if (result.errors) {
    console.log(
      "[ERROR] error updating band map point",
      JSON.stringify(result.errors, null, 2),
    );
    throw result.errors;
  }
  if (result.data?.updateBandMapPoint) {
    return result.data.updateBandMapPoint;
  }
  throw new Error("Could not update band map point");
};
