import { Typography, Box, Container, Button, Avatar } from "@mui/material";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoUserPicture from "src/components/NoUserPicture";
import PhotoCropper from "src/components/PhotoCropper";
import { RootState } from "src/stores";
import { setOnboardingProfilePictureUrl } from "src/stores/OnboardingStore";
import { setUser } from "src/stores/UserStore";
import { createUser } from "./backend";
import { getImageUrl } from "src/utils/images";

const ProfileImage = () => {
  const {
    firstName,
    lastName,
    username,
    profilePictureUrl: profilePictureUrlValue,
  } = useSelector((state: RootState) => state.onboarding);

  const [profilePictureUrl, setProfilePictureUrl] = useState<
    string | null | undefined
  >(profilePictureUrlValue);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNextClick = async () => {
    try {
      const authUser = await Auth.currentAuthenticatedUser();
      if (authUser) {
        const id = authUser.username as string;
        const user = await createUser({
          userId: id,
          firstName,
          lastName,
          username,
          profilePictureUrl,
        });

        if (user) {
          navigate("/");
          dispatch(
            setUser({
              id,
              firstName,
              lastName,
              username,
              isNewUser: false,
              profilePictureUrl,
            }),
          );
        }
      }
    } catch (err) {}
  };
  const handleUpload = (url: string) => {
    const fullUrl = getImageUrl(url);
    setProfilePictureUrl(fullUrl);
    dispatch(setOnboardingProfilePictureUrl({ profilePictureUrl: fullUrl }));
  };
  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Button variant="outlined" onClick={() => navigate("/username")}>
          Back to username
        </Button>
        <Typography variant="h4">Add a profile image?</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}>
        {profilePictureUrl && (
          <Avatar src={profilePictureUrl} sx={{ width: 200, height: 200 }} />
        )}
        {!profilePictureUrl && <NoUserPicture size={200} iconSize="5x" />}
        <PhotoCropper
          onCancel={() => null}
          showPreviewSquare={true}
          onComplete={handleUpload}
          aspect={1}
          containerSx={{ width: { xs: "100%", md: "550px" } }}
          imageSx={{ width: { xs: "100%", md: "400px" } }}
          keyFolder="user-profile"
        />
        {profilePictureUrl && (
          <Button onClick={() => setProfilePictureUrl(null)}>
            Clear image
          </Button>
        )}
        <Button sx={{ mt: 3 }} variant="contained" onClick={handleNextClick}>
          Create account
        </Button>
      </Box>
    </Container>
  );
};

export default ProfileImage;
