import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import { User } from "src/API";
import { isValidUsername } from "src/utils/username";
import * as mutations from "./mutations";
import * as queries from "./queries";

export const validateUsername = async (username: string) => {
  const result = (await API.graphql(
    graphqlOperation(queries.usersByUsername, { username }),
  )) as GraphQLResult<{ usersByUsername: { items: User[] } }>;

  if (result.errors) {
    throw result.errors;
  }
  if (
    result.data &&
    result.data.usersByUsername &&
    result.data.usersByUsername.items.length === 0
  ) {
    return true;
  }
  return false;
};

type CreateUserProps = {
  userId: string;
  firstName: string;
  lastName: string;
  username: string;
  profilePictureUrl?: string | null;
};

type CreateUserResponse = {
  createUser: User;
};

export const createUser = async ({
  userId,
  firstName,
  lastName,
  username,
  profilePictureUrl,
}: CreateUserProps): Promise<User | null> => {
  const lower = username.toLowerCase();
  const valid = await validateUsername(lower);

  if (!isValidUsername(lower)) {
    return null;
  }

  if (!valid) {
    return null;
  }

  const result = (await API.graphql(
    graphqlOperation(mutations.createUser, {
      input: {
        id: userId,
        firstName,
        lastName,
        username: lower,
        profilePictureUrl,
      },
    }),
  )) as GraphQLResult<CreateUserResponse>;

  if (result.errors) {
    console.log("[ERROR] error creating user", result.errors);
  }
  if (result.data?.createUser) {
    return result.data.createUser;
  }
  return null;
};
