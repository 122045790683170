import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { hydrateOnboarding } from "src/stores/OnboardingStore";
import Name from "./Name";
import ProfileImage from "./ProfileImage";
import Username from "./Username";

export const Onboarding = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(hydrateOnboarding());
  }, [dispatch]);
  return (
    <Routes>
      <Route path="/username" element={<Username />} />
      <Route path="/picture" element={<ProfileImage />} />
      <Route path="/" element={<Name />} />
    </Routes>
  );
};
