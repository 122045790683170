import {
  TextField,
  Typography,
  Box,
  Container,
  Button,
  Alert,
  CircularProgress,
} from "@mui/material";
import { debounce } from "lodash";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "src/stores";
import { setOnboardingUsername } from "src/stores/OnboardingStore";
import { isValidUsername } from "src/utils/username";
import { validateUsername } from "./backend";

const UsernameContainer = () => {
  const { username: usernameValue } = useSelector(
    (state: RootState) => state.onboarding,
  );
  const [username, setUsername] = useState<string>(usernameValue);
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [isInUse, setIsInUse] = useState<boolean>(false);
  const [isChecking, setIsChecking] = useState<boolean>(false);

  const searchByUsername = useRef(
    debounce(async (val: string) => {
      setIsInUse(false);
      setIsApproved(false);
      setUsername(val);
      if (val.length < 2 || !isValidUsername(val)) {
        setIsInvalid(true);
      } else {
        const isUnique = await validateUsername(val);
        setIsApproved(isUnique);
        setIsInUse(!isUnique);
        setIsInvalid(false);
        dispatch(setOnboardingUsername({ username: val }));
      }
      setIsChecking(false);
    }, 500),
  ).current;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleChangeUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setIsChecking(true);
    setUsername(val);
    if (val.length < 2) {
      setIsApproved(false);
      setIsInvalid(false);
      setIsChecking(false);
    } else {
      searchByUsername(val);
    }
  };

  const handleNextClick = async () => {
    dispatch(setOnboardingUsername({ username }));
    navigate("/picture");
  };
  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Button variant="outlined" onClick={() => navigate("/")}>
          Back to name
        </Button>
        <Typography variant="h4">Pick a unique username?</Typography>
        <Typography variant="body1">
          This will help others search for you.
        </Typography>
      </Box>
      <TextField
        required
        fullWidth
        id="username"
        value={username}
        onChange={handleChangeUsername}
        label="Username"
        sx={{ mb: 2 }}
      />
      <Alert severity={isInvalid ? "error" : "info"} sx={{ mb: 2 }}>
        <Typography variant="body1">
          Valid characters are: <strong>a-z 0-9 . -</strong>
        </Typography>
      </Alert>
      {!isChecking && isApproved && (
        <Alert severity="success">Valid username!</Alert>
      )}
      {isChecking && <CircularProgress />}
      {!isChecking && isInUse && (
        <Alert severity="error">
          <Typography>Username is already in use</Typography>
        </Alert>
      )}
      {!isChecking && isInvalid && (
        <Alert severity="error">
          <Typography>Username is invalid</Typography>
        </Alert>
      )}
      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          disabled={!isApproved || isChecking}
          onClick={handleNextClick}>
          Next
        </Button>
      </Box>
    </Container>
  );
};

export default UsernameContainer;
