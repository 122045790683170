import { ThemeProvider } from "@mui/material/styles";
import { Footer, Navigation } from "src/containers/Navigation";
import theme from "src/styles/theme";
import { BrowserRouter } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { useCallback, useEffect, useState } from "react";
import { Amplify, Hub, Auth } from "aws-amplify";
import { HubCapsule } from "@aws-amplify/core";
import { RootState } from "src/stores";
import { useDispatch, useSelector } from "react-redux";
import awsConfig from "src/aws-exports";
import { setIsAdmin, setUserAuthed } from "src/stores/AuthStore";
import { Alert, Box, Button, styled, Typography } from "@mui/material";
import "./index.css";
import {
  ThemeProvider as AmplifyThemeProvider,
  Theme as AmplifyTheme,
} from "@aws-amplify/ui-react";
import { getUser } from "src/containers/Profile/backend";
import { Onboarding } from "src/containers/Onboarding";
import { setIsNewUser, setUser } from "src/stores/UserStore";
import { setWantsAuth } from "src/stores/AuthStore";
import { Close } from "@mui/icons-material";
import AllRoutes from "./routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { setEnvironment } from "./stores/ConfigStore";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { cognitoGroups } from "./utils/auth";

import mapboxgl from "mapbox-gl";

mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_API_KEY || "";

Amplify.configure(awsConfig);

const queryClient = new QueryClient();

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

function App() {
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [authUserId, setAuthUserId] = useState<string | null>(null);

  const { isAuthed, wantsAuth } = useSelector((state: RootState) => state.auth);
  const { isNewUser } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const components = {
    Header() {
      return (
        <Box sx={{ mb: 1, display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            startIcon={<Close />}
            onClick={() => dispatch(setWantsAuth(false))}>
            Close
          </Button>
        </Box>
      );
    },
    Footer() {
      return (
        <Box sx={{ mt: 1, display: "flex", justifyContent: "flex-end" }}>
          <Button
            fullWidth
            variant="contained"
            startIcon={<Close />}
            onClick={() => dispatch(setWantsAuth(false))}>
            Cancel Sign In
          </Button>
        </Box>
      );
    },
  };

  const amplifyTheme: AmplifyTheme = {
    name: "Auth Example Theme",
    tokens: {
      colors: {
        background: {
          primary: {
            value: "white",
          },
          secondary: {
            value: "#ddd",
          },
        },
        font: {
          interactive: {
            value: theme.palette.primary.main,
          },
        },
        brand: {},
      },
      components: {
        button: {
          borderRadius: {
            value: "100px",
          },
          primary: {
            backgroundColor: {
              value: theme.palette.primary.main,
            },
            _hover: {
              backgroundColor: {
                value: theme.palette.primary.dark,
              },
            },
          },
        },
        tabs: {
          item: {
            _focus: {
              color: {
                value: theme.palette.primary.main,
              },
            },
            _hover: {
              color: {
                value: theme.palette.primary.main,
              },
            },
            _active: {
              color: {
                value: theme.palette.primary.main,
              },
            },
          },
        },
      },
    },
  };

  const loadAuthUser = useCallback(async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();

      setAuthUserId(currentUser.username);

      const groups = await cognitoGroups();
      if (groups && groups.includes("Admins")) {
        dispatch(setIsAdmin(true));
      }

      dispatch(setUserAuthed(Boolean(currentUser)));
    } catch (e) {
      if (e !== "The user is not authenticated") {
        console.log("[ERROR] Error loading user", e);
      }
    } finally {
      setIsLoadingUser(false);
    }
  }, [dispatch]);

  const loadUserProfile = useCallback(async () => {
    try {
      if (authUserId) {
        const user = await getUser(authUserId);
        if (!user) {
          dispatch(setIsNewUser());
        } else {
          dispatch(
            setUser({
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              username: user.username,
              isNewUser: false,
              profilePictureUrl: user.profilePictureUrl,
            }),
          );
        }
      }
    } catch (e) {
      console.log("[ERROR] Error loading user", e);
    }
  }, [authUserId, dispatch]);
  const renderAuthedRoutes = () => {
    if (isNewUser) {
      return <Onboarding />;
    }
    return <AllRoutes isAuthOnly={true} />;
  };
  const renderUnAuthedRoutes = () => {
    return <AllRoutes />;
  };

  useEffect(() => {
    const authCb = async (authEvent: HubCapsule) => {
      const { payload } = authEvent;
      // const { event } = payload;
      if (payload.event === "signIn") {
        try {
          const user = await Auth.currentAuthenticatedUser();
          if (user) {
            dispatch(setUserAuthed(true));
            await loadAuthUser();
            loadUserProfile();
          }
        } catch (e) {
          console.log("[ERROR] Error running authCb", e);
          dispatch(setUserAuthed(false));
        }
      } else if (payload.event === "signOut") {
        dispatch(setUserAuthed(false));
        setIsLoadingUser(false);
      } else if (payload.event === "signIn_failure") {
        setError("Sign in failed. Please try again.");
        setTimeout(() => {
          setError(null);
        }, 2000);
      }
    };
    Hub.listen("auth", authCb);
    loadAuthUser();
    loadUserProfile();
    return () => {
      Hub.remove("auth", authCb);
    };
  }, [dispatch, loadAuthUser, loadUserProfile]);

  useEffect(() => {
    dispatch(setEnvironment(process.env.NODE_ENV));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderRoutes = () => {
    if (error || isLoadingUser) {
      return null;
    }
    return (
      <>
        {!isAuthed && renderUnAuthedRoutes()}
        {((!isAuthed && wantsAuth) || isAuthed) && (
          <Authenticator variation="modal" components={components}>
            <div className="App">{renderAuthedRoutes()}</div>
          </Authenticator>
        )}
      </>
    );
  };
  return (
    <ThemeProvider theme={theme}>
      <AmplifyThemeProvider theme={amplifyTheme}>
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BrowserRouter>
              <Box sx={{ minHeight: "80vh" }}>
                <Navigation />
                <Offset />
                {isLoadingUser && <Typography>Loading user...</Typography>}
                {error && (
                  <Alert variant="filled" severity="error">
                    {error}
                  </Alert>
                )}
                {renderRoutes()}
              </Box>
              <Footer />
            </BrowserRouter>
          </LocalizationProvider>
        </QueryClientProvider>
      </AmplifyThemeProvider>
    </ThemeProvider>
  );
}

export default App;
